<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-form ref="form" :model="formData" label-width="100px">
      <el-form-item label="订单号" prop="orderNo" :rules="rules">
        <el-input
          v-model="formData.orderNo"
          size="small"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="金额" prop="amount" :rules="rules">
        <el-input
          v-model="formData.amount"
          size="small"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="金额类型" prop="amountType" :rules="rules">
        <el-select
          v-model="formData.amountType"
          placeholder="请选择"
          size="small"
        >
          <el-option
            v-for="item in amountType"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="付款类型" prop="payType" :rules="rules">
        <SelectDict v-model="formData.payType" dictCode="pay:type" />
      </el-form-item>
      <el-form-item label="应付日期" prop="billDate" :rules="rules">
        <el-date-picker
          v-model="formData.billDate"
          value-format="yyyy-MM-dd"
          size="small"
          type="date"
          placeholder="选择日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="账期类型" prop="billType" :rules="rules">
        <SelectDict v-model="formData.billType" dictCode="finance:billType" />
      </el-form-item>
      <el-form-item label="外部交易号" prop="openTradeNo">
        <el-input
          v-model="formData.openTradeNo"
          size="small"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="备注">
        <el-input
          v-model="formData.remark"
          type="textarea"
          size="small"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-button
          type=""
          size="small"
          @click="$router.push({ name: 'FinanceReport' })"
          >取消</el-button
        >
        <el-button type="primary" size="small" @click="save">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { amountType } from "@/enum/dict/index.js";
import { addFinanceBillAPI } from "./api";
import SelectDict from "@/views/components/select-dict.vue";
export default {
  name: "AddOrEdit",
  components: { SelectDict },
  data() {
    return {
      rules: [{ required: true, message: "该项不能为空", trigger: "blur" }],
      formData: {
        orderNo: "",
        amount: "",
        amountType: "",
        payType: "",
        billDate: "",
        billType: "",
        openTradeNo: "",
        remark: "",
      },
      amountType,
    };
  },

  mounted() {},

  methods: {
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          addFinanceBillAPI(this.formData).then(() => {
            this.$message.success("添加成功");
            this.$router.push({ name: "FinanceReport" });
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .el-form {
    .el-form-item {
      .el-input,
      .el-select,
      .el-textarea {
        width: 400px;
      }
    }
  }
}
</style>
